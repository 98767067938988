import 'tailwindcss/tailwind.css'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://7f358cc605e84834b64b890a1059b949@o667634.ingest.sentry.io/5767142",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

// @ts-ignore
function MyApp({ Component, pageProps }) {
    return <Component {...pageProps} />
}

export default MyApp